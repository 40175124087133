function verificaTopoPosition(){
	var windowPosition = $(window).scrollTop();
	var alturaBarraTopo = $('.faixa-topo').outerHeight();


	if(windowPosition >= alturaBarraTopo){

		$('.topo').css('min-height', $('.topo').outerHeight());
		$('.topo').addClass('fx')

	}else{
		$('.topo').removeAttr('style');
		$('.topo').removeClass('fx');
	}
};

verificaTopoPosition();

$(window).scroll(function(){

	verificaTopoPosition();

});