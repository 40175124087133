$('[data-scroll-to]').click(function(e){
	e.preventDefault();

	var target = $(this).data('scroll-to');
	var targetPosition = $(target).offset();

	if(targetPosition){
		var th = 0;

		if(window.innerWidth < 1190){
			th = $('.topo').outerHeight()
		} else {
			th = $('.segura-menu').outerHeight()
		}

		$('body, html').animate({
			scrollTop : targetPosition.top - (th+50)
		}, 1000);
	}

	$('.main-navigation').removeClass('active');
	$('.bg-menu').fadeOut(600);
	$('.mbl-menu').toggleClass('active');
	$('[data-toggle="menu"]').removeClass('change');
});